import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout'
import SEO from "../components/seo";
import Header from '../components/headerEn';
import Footer from '../components/footerEn';

class InvestorEnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state =({
      warning: false,
      infoWarning: false,
      label: "",
      quarter: "",
    })
  }

  activateWarning() {
    this.setState({
      warning: true,
    })
  }

  deactivateWarning() {
    this.setState({
      warning: false,
    })
  }

  activateInfoSiteWarning() {
    this.setState({
      infoWarning: true,
    })
  }  

  deactivateInfoSiteWarning() {
    this.setState({
      infoWarning: false,
    })
  }
  showLabel(label) {
    if (label === this.state.label) {
      this.setState({
        label: "",
        quarter: "",
      })
    } else {
      this.setState({
        label: label,
        quarter: "",
      })
    }
  }
  showQuarter(quarter) {
    if (quarter === this.state.quater) {
      this.setState({
        quarter: "",
      })
    } else {
      this.setState({
        quarter: quarter,
      })
    }
  }

  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Investors" />
        <Header />
        { !this.state.warning ? <span /> : 
        <div className="is-floating-warning">
          <section className="section page-content more-padding" style={{ padding: "10%", textAlign:"center"}}>
            <h2>You are leaving K Laser website</h2>
            <div style={{ padding: "2em", textAlign:"left",  }}>
              <p>You are now leaving our web site. The web site you wish to link to is owned or operated by an entity other than K Laser. We do not control this third party web site. We are providing this link for your convenience only and we are not responsible for the availability of or the content located on or through this third party web site, the accuracy, completeness, timeliness, or legality of any information contained in this third party web site, and any opinions expressed therein, nor for any link contained in this third party web site. This third party web site is not investigated, monitored, or checked for accuracy, completeness, timeliness, or legality, by K Laser. The link from our web site to this third party web site does not imply that we approve of, endorse, or recommend this third party web site. We expressly disclaim all warranties, express or implied, as to the accuracy, legality, reliability, timeliness, quality, or validity of any content on this third party web site.</p>
              <p>🔗 https://mis.twse.com.tw/stock/fibest.jsp?stock=2461&lang=en_us</p>
            </div>
            <a href="https://mis.twse.com.tw/stock/fibest.jsp?stock=2461&lang=en_us" className="link-wrapper big-link w-inline-block">
              <div className="link-text">Confirm</div>
            </a>
            <div className="link-text" style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.deactivateWarning()}><p>Back</p></div>
          </section>
        </div>} 
        { !this.state.infoWarning ? <span /> : 
        <div className="is-floating-warning">
          <section className="section page-content more-padding" style={{ padding: "10%", textAlign:"center"}}>
            <h2>You are leaving K Laser website</h2>
            <div style={{ padding: "2em", textAlign:"left",  }}>
              <p>You are now leaving our web site. The web site you wish to link to is owned or operated by an entity other than K Laser. We do not control this third party web site. We are providing this link for your convenience only and we are not responsible for the availability of or the content located on or through this third party web site, the accuracy, completeness, timeliness, or legality of any information contained in this third party web site, and any opinions expressed therein, nor for any link contained in this third party web site. This third party web site is not investigated, monitored, or checked for accuracy, completeness, timeliness, or legality, by K Laser. The link from our web site to this third party web site does not imply that we approve of, endorse, or recommend this third party web site. We expressly disclaim all warranties, express or implied, as to the accuracy, legality, reliability, timeliness, quality, or validity of any content on this third party web site.</p>
              <p>🔗 https://mops.twse.com.tw/mops/web/index</p>
            </div>
            <a href="https://mops.twse.com.tw/mops/web/index" className="link-wrapper big-link w-inline-block">
              <div className="link-text">Confirm</div>
            </a>
            <div className="link-text" style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.deactivateInfoSiteWarning()}><p>Back</p></div>
          </section>
        </div>} 
        <div className="page-content">
          <div className="section">
            <div className="container w-container">
              <div className="w-col w-col-9 w-col-stack">
                <div id="1">
                  <h2>Overview</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "English" && document.node.category === "Overview" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                  {/*<div>
                    <iframe
                      className="airtable-embed"
                      src="https://airtable.com/embed/shr72WhRSXsPlcmfB?backgroundColor=purple"
                      frameBorder="0"
                      width="100%"
                      height="400"
                      style={{ background:"transparent", border: "1px solid #ccc" }}
                    />
                    <p style={{ fontSize:"1em" }}>*Year 2019 figures have not been audited.</p>
                    <p style={{ fontSize:"1em" }}>** Starting 2013, K Laser prepares financial statements in accordance with TIFRS (International Financial Reporting Standards as endorsed in R.O.C.), K Laser will report only consolidated financial figures from January 2013.</p>
                  </div>*/}
                  <div style={{ marginBottom: "3em" }}>
                    <div style={{ padding: "2em" }}>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Organizational_Chart")}
                      >
                        <div className="link-text-second">＋Org Chart</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Sales_Report")}
                      >
                        <div className="link-text-second">＋Sales Report</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Financial_Statement")}
                      >
                        <div className="link-text-second">＋Financial Statement</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Financial_Report")}
                      >
                        <div className="link-text-second">＋Financial Report</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Annual_Report")}
                      >
                        <div className="link-text-second">＋Annual Report</div>
                        <div className="link-line-second"></div>
                      </button>
                    </div>
                    <div>
                    { this.state.label === "Organizational_Chart" ? (
                      data.Organizational_Chart.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Sales_Report" ? (
                      data.Sales_Report.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Financial_Statement" ? (
                      <div>
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("first")}
                        >
                          <div className="link-text-second">1st Qtr</div>
                          <div className="link-line-second"></div>
                        </button>
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("second")}
                        >
                          <div className="link-text-second">2nd Qtr</div>
                          <div className="link-line-second"></div>
                        </button>
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("third")}
                        >
                          <div className="link-text-second">3rd Qtr</div>
                          <div className="link-line-second"></div>
                        </button>
                        <button
                          style={{ margin: "1em"}}
                          className="line-wrapper-second second in-footer-nav w-inline-block"
                          onClick={ () => this.showQuarter("fourth")}
                        >
                          <div className="link-text-second">Annual</div>
                          <div className="link-line-second"></div>
                        </button>
                      <div>
                      {data.Financial_Statement.edges.map(document => (
                        <div key={document.node.id}>
                          { document.node.quarter === this.state.quarter ? 
                            <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a> : <span />
                          }
                        </div>
                      ))}    
                      </div>
                    </div>) : <span />}
                    { this.state.label === "Financial_Report" ? (
                    <div>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("first")}
                      >
                        <div className="link-text-second">1st Qtr</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("second")}
                      >
                        <div className="link-text-second">2nd Qtr</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("third")}
                      >
                        <div className="link-text-second">3rd Qtr</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showQuarter("fourth")}
                      >
                        <div className="link-text-second">Annual</div>
                        <div className="link-line-second"></div>
                      </button>
                      <div>
                      {data.Financial_Report.edges.map(document => (
                        <div key={document.node.id}>
                          { document.node.quarter === this.state.quarter ? 
                            <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a> : <span />
                          }
                        </div>
                      ))}    
                      </div>
                    </div>) : <span /> }
                    { this.state.label === "Annual_Report" ? (
                      data.Annual_Report.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    </div>
                  </div>
                </div>
                <div className="investor-section" id="2">
                  <h2>Corporate Governance</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "English" && document.node.category === "Management" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                  <div>
                    <div style={{ padding: "2em"}}>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Audit_Statement")}
                      >
                        <div className="link-text-second">＋Audit Statement</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Board_Resolution")}
                      >
                        <div className="link-text-second">＋Board Resolution</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Internal_Policy")}
                      >
                        <div className="link-text-second">＋Internal Policy</div>
                        <div className="link-line-second"></div>
                      </button>
                    </div>
                    { this.state.label === "Audit_Statement" ? (
                      data.Audit_Statement.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Board_Resolution" ? (
                      data.Board_Resolution.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Internal_Policy" ? (
                      data.Internal_Policy.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                  </div>
                </div>
                <div className="investor-section" id="3">
                  <h2>Shareholder Services</h2>
                  <h6>Stock Quotes</h6>
                  <p>Visit link below to Taiwan Stock Exchange</p>
                  <p><span style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.activateWarning() }>K Laser (TWSE: 2461) 🔗</span></p>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "English" && document.node.category === "Investor" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                    <h4>Major message</h4>
                    <p>Visit link below to the Market Observation Post System</p>
                    <p><span style={{ color: "blue", cursor: "pointer" }} onClick={ () => this.activateInfoSiteWarning() }>Market Observation Post System 🔗</span></p>
                  </div>
                  <div>
                    <div style={{ padding: "2em"}}>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Investor_Presentation")}
                      >
                        <div className="link-text-second">＋Investor Presentation</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Major_Shareholders")}
                      >
                        <div className="link-text-second">＋Major Shareholders</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Shareholders_Meeting")}
                      >
                        <div className="link-text-second">＋Shareholders Meeting</div>
                        <div className="link-line-second"></div>
                      </button>
                      <button
                        style={{ margin: "1em"}}
                        className="line-wrapper-second second in-footer-nav w-inline-block"
                        onClick={ () => this.showLabel("Dividend_Distribution")}
                      >
                        <div className="link-text-second">＋Dividend Distribution</div>
                        <div className="link-line-second"></div>
                      </button>
                    
                    </div>
                    { this.state.label === "Investor_Presentation" ? (
                      data.Investor_Presentation.edges.map(document => (
                        <div key={document.node.id}>
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Major_Shareholders" ? (
                      data.Major_Shareholders.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Shareholders_Meeting" ? (
                      data.Shareholders_Meeting.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                    { this.state.label === "Dividend_Distribution" ? (
                      data.Dividend_Distribution.edges.map(document => (
                        <div key={document.node.id}>                  
                          <a href={document.node.file ? document.node.file.publicURL : ""} target="_blank" rel="noopener noreferrer"><p>{document.node.name}</p></a>
                        </div>
                      ))
                    ) : <span /> }
                  </div>
                </div>
                <div className="investor-section"  id="4">
                  <h2>Stakeholder</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "English" && document.node.category === "Stakeholder" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                </div>
                <div className="investor-section" id="5">
                  <h2>Social Responsibility</h2>
                  <div className="w-dyn-list">
                    {data.allStrapiInvestor.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "English" && document.node.category === "Social" ) ?
                      <div className="w-dyn-items">
                        <h6>{document.node.title}</h6>
                        <ReactMarkdown source={document.node.content} />
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-col w-col-3 w-col-stack">
                <div className="sidebar">
                  <div className="top-margin big">
                    <h4 className="sidebar-title dark">Quick Access</h4>
                    <div className="w-dyn-list">
                      <div className="w-dyn-items">
                        <div className="w-dyn-item">
                          <a href="#1" className="w-inline-block">
                            <div className="meta-blog data normal">Overview</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#2" className="w-inline-block">
                            <div className="meta-blog data normal">Corporate Governance</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#3" className="w-inline-block">
                            <div className="meta-blog data normal">Shareholder Services</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#4" className="w-inline-block">
                            <div className="meta-blog data normal">Stakeholder</div>
                          </a>
                        </div>
                        <div className="w-dyn-item">
                          <a href="#5" className="w-inline-block">
                            <div className="meta-blog data normal">Social Responsibility</div>
                          </a>
                        </div>                                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default InvestorEnPage

export const pageQuery = graphql`  
  query InvestorEnQuery {
    Organizational_Chart: allStrapiDocument(filter: {label: {eq: "Organizational_Chart"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Sales_Report: allStrapiDocument(filter: {label: {eq: "Sales_Report"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Financial_Statement: allStrapiDocument(filter: {label: {eq: "Financial_Statement"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Annual_Report: allStrapiDocument(filter: {label: {eq: "Annual_Report"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Financial_Report: allStrapiDocument(filter: {label: {eq: "Financial_Report"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Board_Resolution: allStrapiDocument(filter: {label: {eq: "Board_Resolution"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Audit_Statement: allStrapiDocument(filter: {label: {eq: "Audit_Statement"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Internal_Policy: allStrapiDocument(filter: {label: {eq: "Internal_Policy"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Shareholders_Meeting: allStrapiDocument(filter: {label: {eq: "Shareholders_Meeting"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Major_Shareholders: allStrapiDocument(filter: {label: {eq: "Major_Shareholders"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Investor_Presentation: allStrapiDocument(filter: {label: {eq: "Investor_Presentation"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    Dividend_Distribution: allStrapiDocument(filter: {label: {eq: "Dividend_Distribution"}, language: {eq: "English"}}, sort: {fields: year, order: DESC}) {
      edges {
        node {
          name
          file {
            publicURL
          }
          quarter
        }
      }
    }
    allStrapiInvestor(sort: {fields: id, order: ASC}) {
      edges {
        node {
          id
          title
          content
          category
          language
        }
      }
    }
  }
`